import React, { useState, useEffect, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useSelector, useDispatch,  } from 'react-redux'

import { fetchsingleproduct, updateProductMemo, disableStock, enableStock, deleteProductAddons, updateProductPrice, fetchproducts, updateCategory, fetchAllCategories, updateProductAddons } from '../Redux/AllCategories/AllCategoriesActions';
import { Link, useHistory, useLocation} from "react-router-dom";
import { fetchAllAddons, fetchAllAddonsInOne } from '../Redux/AllBook/AllBookActions';
import { MDBBadge } from 'mdbreact';
import { addProduct, fetchAllProducts, fetchProductsSuccess } from '../Redux/AllProduct/AllProductsActions';
import { config } from '../Redux/config';
import { FETCH_ALLPRODUCTS_SUCCESS } from '../Redux/AllProduct/AllProductsConstants';
import toast from 'react-hot-toast';



export const fetchAllCategoriesSuccess = (all_categories_success) =>{
  return{
    type : FETCH_ALLPRODUCTS_SUCCESS,
    payload : all_categories_success
  }
}
let addonGroupArr=[];


let categoryArr=[];
function AddProduct() {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors }
  // } = useForm();
  


  const allCategoriesData = useSelector(state =>state.AllCategories)
  
  // store data access End
    const dispatch = useDispatch()  // for accessing the redux function
  
    // component all states define start
    const [allExpenseResult,setAllExpenseResult] = useState([])
    const [allCategoriesResult,setAllCategoriesResult] = useState([])
    const [allExpenseInfo,setAllExpenseInfo] = useState([])
    const [categoryStatus,setCategoryStatus] = useState('ACTIVE')
    const [currentcatstaus,setCurrentcatstaus] = useState(true)
    const [activestate,setActivestate] = useState('')
    const [row,setRow] = useState([])
    
    const [editCatMode,setEditCatMode] = useState(false)
    const [editSelectedCat,setEditSelectedCat] = useState(null)
    const [inputValues,setInputValues] = useState({
      name:"",
      description:"",
      shortdescription:"",
      price:"",
      is_alcohol:false,
      is_sugary_item:false,
      categories:[],
      addons:[],
      productImageUrl:"",
      type:"",
      nameKey:"type",
      isRequired:false,
      requires:0,
      isGlobal:true,
      isSystem:false,

})

const [inputValuesdefault,setInputValuesdefault] = useState({
  name:"",
  description:"",
  shortdescription:"",
  price:"",
  is_alcohol:false,
  is_sugary_item:false,
  categories:[],
  addons:[],
  productImageUrl:"",
  type:"",
  nameKey:"type",
  isRequired:false,
  requires:0,
  isGlobal:true,
  isSystem:false,

})

    const [datatable, setDatatable] = useState({
      columns: [
        {
          label: 'ID',
          field: 'id',
          sort: 'asc',
          width: 20,
        },
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 200
        },
        {
          label: 'Expense Type',
          field: 'expensetype',
          sort: 'asc',
          width: 200
        },
        {
          label: 'Amount',
          field: 'amount',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Status',
          field: 'status',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Phone',
          field: 'phone',
          sort: 'asc',
          width: 1000
        },
        {
          label: 'Email',
          field: 'email',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Date',
          field: 'date',
          sort: 'asc',
          width: 100
        }
      ],
      rows: []
    });
    // component all states define End
  
     //hooks start
     // fetch allExpense  api hook start
     useEffect(() =>{
       dispatch(fetchAllCategories())
     },[dispatch])
  // fetch allExpense  api hook End
  
  // add data of allAssignBook api into constant,hook start
     useMemo(() =>{
       if(allCategoriesData && allCategoriesData.all_categories){
         setAllCategoriesResult(allCategoriesData.all_categories.data)
       }
       
     },[allCategoriesData])
  // add data of allAssignBook api into constant,hook End
  
  // when allExpenseResult data change than data add into constant,hook start
     useMemo(() =>{
       if(allExpenseResult && allExpenseResult.data){
         setAllExpenseInfo(allExpenseResult.data)
       }
     },[allExpenseResult])
  // when allExpenseResult data change than data add into constant,hook End
  
  //when allExpenseInfo data change than data add into constant,hook start
  useMemo(()=>{
    if(allExpenseInfo && allExpenseInfo.length > 0){
      let arrray = []
      allExpenseInfo.map((item,index)=>{
        let new_object = {
          id:item.Expenseid,
          name: item.Name,
          expensetype:item.ExpenseType,
          amount:item.Amount,
          status:(
          <MDBBadge pill color={item.Status == "Paid" ? 'success' : 'danger'} className={`p-7 ${item.Status == "Paid" ? 'pl-17 pr-17' : 'pl-10 pr-10'}`} key={index} searchvalue={item}>
            {item.Status}
          </MDBBadge>
        ),
          phone:item.Phone,
          email:item.Email,
          date:item.Date
        }
        console.log("new_object",new_object)
        arrray.push(new_object)
      })
      console.log("arrray",arrray)
      setRow(arrray)
    }
  
  },[allExpenseInfo])
  //when allExpenseInfo data change than data add into constant,hook end
  
  //when row data change than data add into constant,hook start
  useMemo(() =>{
    if(row && row.length > 0){
      setDatatable({...datatable,rows:row})
    }
  },[row])
  //when row data change than data add into constant,hook end
     //hooks end
  

  

  
  // examHandler function start
     const editCatStatusClick = (e) =>{
       console.log('item', e.target.value);
    setInputValues({...inputValues, catStatus:e.target.value});
     console.log('inputValues', inputValues);
   }

  // category list
  const [checkedItems, setCheckedItems] = useState({});

  const [saveState, setSaveState] = useState('')

  const handleChange2 = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
    if (event.target.checked === true) {
      setSaveState('add');
      // dispatch(updateProductAddons(event.target.name))
    }
    else {
      setSaveState('delete');
      // dispatch(deleteProductAddons(event.target.name))
    }
    // console.log('checkedItems', checkedItems)
  };

  // end of category list
  const Checkbox = ({ type = "checkbox", id, name, checked = false, onChange }) => {

    return (
      <input type={type} id={id} name={name} checked={checked} onChange={onChange} className="stv-radio-button" />
    );
  };

//  for addons
 // store data access start
 const productGroupData = useSelector(state => state.AllCategories)
 
 const addonGroupData = useSelector(state => state.AllBook);
 // store data access End
//  const dispatch = useDispatch()  // for accessing the redux function
 const { state } = useLocation()
 const history = useHistory();
 // component all states define start

 const [productAddonsResult, setProductAddonsResult] = useState([]);
 const [productAddonsloaded, setProductAddonsloaded] = useState('')
//  const [saveState, setSaveState] = useState('')
 const [indexState, setIndexState] = useState(0)
 const [allGroupResult, setGroupResult] = useState([])
 const [selectedGroups, setSelectedGroups] = useState({});
 const [editProductResp, setEditProductResp] = useState({
   type: '',
   message: ''
 })

 const [errorMessage, setErrorMessage] = useState('')



 const goToBack = () => {
   history.goBack();
 }

 useEffect(() => {
  dispatch(fetchAllAddonsInOne());
   if (!productGroupData.single_product.data) {
     setIndexState(0);
   }
 }, [dispatch]);


 useMemo(() => {
   const groups = [];
   if (addonGroupData && addonGroupData.all_book && addonGroupData.all_book.data && addonGroupData.all_book.data.length > 0) {
     setGroupResult(addonGroupData.all_book.data)
     addonGroupData.all_book.data.map((item, index) => {
       groups.push({ value: item.addOnGroupId, label: item.name })
     })
   }
   else {
     setGroupResult([])
   }
   setSelectedGroups(groups)
 }, [addonGroupData])

 useMemo(() => {
   if (productGroupData && productGroupData.single_product && productGroupData.single_product.data) {
     const selectedAddons = {};
     setProductAddonsloaded(false)
     let addonGroups = productGroupData.single_product.data.reverse();
     setProductAddonsResult(addonGroups);
     if (productGroupData.single_product_loaded) {
       setProductAddonsloaded(true);
     }
     
     addonGroups.map((item, index) => {
       selectedAddons[item.addOnGroupId] = true;
     })
     setIndexState(0);
     setCheckedItems(selectedAddons);
   }
 }, [productGroupData]);

 useMemo(() => {
   if (productGroupData && productGroupData.add_product_group && productGroupData.add_product_group_loaded && saveState == 'add') {
     setSaveState('');
     dispatch(fetchsingleproduct(state.productId));
   }
 }, [productGroupData])


 useMemo(() => {
   if (productGroupData && productGroupData.delete_product_group && productGroupData.delete_product_group_loaded && saveState == 'delete') {
     setSaveState('');
     dispatch(fetchsingleproduct(state.productId));
   }
 }, [productGroupData])




 useEffect(() => {
  dispatch(fetchAllAddonsInOne());
   if (!productGroupData.single_product.data) {
     setIndexState(0);
   }
 }, [dispatch]);


 useMemo(() => {
   const groups = [];
   if (addonGroupData && addonGroupData.all_book && addonGroupData.all_book.data && addonGroupData.all_book.data.length > 0) {
     setGroupResult(addonGroupData.all_book.data)
     addonGroupData.all_book.data.map((item, index) => {
       groups.push({ value: item.addOnGroupId, label: item.name })
     })
   }
   else {
     setGroupResult([])
   }
   setSelectedGroups(groups)
 }, [addonGroupData])

 useMemo(() => {
   if (productGroupData && productGroupData.single_product && productGroupData.single_product.data) {
     const selectedAddons = {};
     setProductAddonsloaded(false)
     let addonGroups = productGroupData.single_product.data.reverse();
     setProductAddonsResult(addonGroups);
     if (productGroupData.single_product_loaded) {
       setProductAddonsloaded(true);
     }
     
     addonGroups.map((item, index) => {
       selectedAddons[item.addOnGroupId] = true;
     })
     setIndexState(0);
     setCheckedItems(selectedAddons);
   }
 }, [productGroupData]);





 

// end of addons list

// preview image

const [btn,setBtn] = useState(false);
const [file, setFile] = useState();
const url =`${config.api_root}/api/v1/media/upload?user_id=${config.user_id}`;
// var saveButton = document.getElementById("save-button");

// // Disable the button initially
// saveButton.disabled = true;
const [showImg,setShowImg] = useState(false);
// Define a function to handle the file input change
function handleChange(e) {
setFile(URL.createObjectURL(e.target.files[0]));

// Get the file object from the event
var file = e.target.files[0];
setShowImg(true);
// Get the ArrayBuffer from the file object
file
.arrayBuffer()
.then((buffer) => {
// Fetch the API with the ArrayBuffer as the body
return fetch(url, {
method: "POST",
headers: {
Accept: "application/json",
Authorization: "Bearer " + config.token,
"Content-Type": file.type, // e.g. 'image/png', 'image/jpeg', 'image/jpg'
},
body: buffer, // pass the ArrayBuffer directly
});
})
.then((response) => {
// Check the status of the response
if (response.status === 200) {
// The API request is successful
// Enable the button
setBtn(true);
// Handle the response data
return response.text();
} else {
// The API request is not successful
// Throw an error
throw new Error("API request failed");
}
})
.then((data) => {
// Handle the response data
console.log(data); // data is a string
dispatch(fetchProductsSuccess(data));
var jsonString = data;

// Parse the JSON string into a JavaScript object
var jsonObject = JSON.parse(jsonString);

// Get the imageId from the object
var imageId = jsonObject.imageId;
var imagePoint = jsonObject.endpoint;

// Print the imageId
console.log(imageId);

// Create a new XMLHttpRequest object
var xhr = new XMLHttpRequest();

// Set the responseType to blob
xhr.responseType = "blob";

// Use the open method to specify the GET method and the imageId URL
xhr.open("GET", imageId);

// Use the send method to send the request
xhr.send();

// Use the onload event handler to execute a function when the response is ready
xhr.onload = function () {
// Create a new FileReader object
var reader = new FileReader();

// Use the readAsDataURL method to read the response blob as a data URL
reader.readAsDataURL(xhr.response);

// Use the onloadend event handler to execute another function when the reading is finished
reader.onloadend = function () {
// Get the data URL of the image, which contains the Base64 encoded string
var dataURL = reader.result;

// Optionally, remove the data:image/png;base64, prefix and get only the Base64 string
var base64 = dataURL.replace(/^data:image\/png;base64,/, "");

// Print the Base64 string to the console
console.log(base64);
};
};

setInputValues({ ...inputValues, productImageUrl: imagePoint });
})
.catch((error) => {
// Handle the error
console.error(error);
// Disable the button
setBtn(false);
});
}
  

// end of preview image

// start of add product api
// const [currentPage, setCurrentPage] = useState(0);
// const [totalPages, setTotalPages] = useState(0);
const [show,setShow] = useState(false);
const productHandler = (event) =>{
  event.preventDefault()
  const exam_info = inputValues;
  dispatch(addProduct(exam_info));
  setShow(true)
  // setInputValues(inputValuesdefault)
  // history.push('/product')
  // dispatch(fetchAllProducts(currentPage,totalPages))
  
// toast("Product Added Successfully!")
}




// end of it

  return (
    <>
      
      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={goToBack}>Back</button>
        <h3>Products</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
          <Link to="/product">All Products</Link>
          </li>
          <li>Add Products</li>

        </ul>


      </div>

<div className="row">
      <div className="col-12-xxxl col-12">
        
         
               
                    <h3>Add Product</h3>

            
                </div>
                </div>   
    
<form onSubmit={(e) =>productHandler(e)}>



      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">
         
               
                <div className="card-body">  
       


                {errorMessage ?
          <p className="alert alert-danger w-auto" role="alert">
            {errorMessage}
          </p> : <p></p>
        }
                
                  <div className="row">

            
                    <div className="col-lg-6 col-12 form-group">
                    <label>Product Name</label>
                      <input type="text" placeholder="Product Name" className="form-control" value={inputValues.name} onChange={(e) =>setInputValues({...inputValues,name:e.target.value})} required/>
                    </div>
                    <div className="col-lg-6 col-12 form-group">
                    <label>Product Short Description</label>
                        <textarea placeholder="Product Short Description" className="form-control" value={inputValues.shortdescription} onChange={(e) =>setInputValues({...inputValues,shortdescription:e.target.value})} required>
                      
                        </textarea>
                     </div> 
                    <div className="col-lg-6 col-12 form-group">
                    <label>Product Description</label>
                      <textarea style={{height:"10vh"}} placeholder="Product Description" className="form-control" value={inputValues.description} onChange={(e) =>setInputValues({...inputValues,description:e.target.value})} required>
                      
                      </textarea>
                    </div>

                  
                      
                  
                   
                  </div>
           
                </div>
                <div className="row">
        <div className="col-6-xxxl col-12">
        <div className="card-body mg-t-20 pad-5">
                   <div className="row btmrow" style={{background:'none', border: 'none'}}>
                      <div className="col-lg-6" style={{textAlign: 'left', paddingTop:'10px'}}>
                    
                      <input type="checkbox" id="true" name="email" value="true"  onChange={(e) =>setInputValues({...inputValues,is_alcohol:!!e.target.value})}/>
&nbsp;&nbsp;
  <label>Alcohol_Items</label>
                      <br/>
                      
                     </div>  

                      
                    
               
                    </div>
                </div>
                </div>
                <div className="col-6-xxxl col-12">
                <div className="card-body mg-t-20 pad-5">
                   <div className="row btmrow" style={{background:'none', border: 'none'}}>
                      <div className="col-lg-6" style={{textAlign: 'left', paddingTop:'10px'}}>
                      <input type="checkbox" id="true" name="email" value="true" onChange={(e) =>setInputValues({...inputValues,is_sugary_item:!!e.target.value})}/>
&nbsp;&nbsp;
                      <label>Sugary_Items</label>
                   
    
                     </div>  

                      
                    
               
                    </div>
                </div>
                </div>
</div>
             
                <div className="card-body mg-t-20 pad-5" style={{display:"none"}}>
                   <div className="row btmrow" style={{background:'none', border: 'none'}}>
                      <div className="col-lg-4" style={{textAlign: 'left', paddingTop:'10px'}}>
                      <label>Product Type </label>
                      <input type="text" value='Simple' className="form-control"  required/>                                
                      </div>  
                      <div className="col-lg-4" style={{textAlign: 'left', paddingTop:'10px'}}>
                      <label>Virtual</label>
                      <input type="text" value='false' className="form-control"  required/>                                
                      </div>      
                      <div className="col-lg-4" style={{textAlign: 'left', paddingTop:'10px'}}>
                      <label>Downloads </label>
                      <input type="text" value='false' className="form-control"  required/>                                
                      </div> 
                      <div className="col-lg-4" style={{textAlign: 'left', paddingTop:'10px'}}>
                      <label>Time Slot</label>
                      <input type="text" value='false' className="form-control"  required/>                                
                      </div> 
                      <div className="col-lg-4" style={{textAlign: 'left', paddingTop:'10px'}}>
                      <label>Price Slot </label>
                      <input type="text" value='false' className="form-control"  required/>                                
                      </div> 
                      <div className="col-lg-4" style={{textAlign: 'left', paddingTop:'10px'}}>
                      <label>Status </label>
                      <input type="text" value='true' className="form-control"  required/>                                
                      </div> 
                    </div>
                </div>

                <div className="row">
        <div className="col-6-xxxl col-12">
                <div className="card-body mg-t-20 pad-l-5">
                 
                
                    <div className="col-lg-12 col-12 form-group">
                    <label>Regular Price:</label>
                      <input type="number" placeholder="Regular Price" className="form-control"  required/>
                     
                    </div>
                    <div className="col-lg-12 col-12 form-group">
                    <label>Sale Price:</label>
                      <input type="number" placeholder="Sale Price" className="form-control" value={inputValues.price} onChange={(e) =>setInputValues({...inputValues,price:e.target.value})} required/>
                     
                    </div>
                   
                    <div className="col-lg-12 col-12 form-group">
                    <label>Menu Order:</label>
                      <input type="number" placeholder="Menu Order" className="form-control" required/>
                     
                     </div>   
                   

                   
                    
                  </div>
                </div>
                <div className="col-6-xxxl col-12">
                <div className="card-body mg-t-20 pad-l-5" style={{height:"33vh"}}>
                 
                 
                    
                    <div className="col-lg-12 col-12 form-group">
                    <label>Product Image:</label>
                      <input type="file" className="form-control" onChange={handleChange} required/>
                     
                    </div>
                    <div className="col-lg-6 col-12 form-group">
                    <label>Image Preview:</label>
                    <br/>
                    {showImg?(<img src={file} width={200}  style={{height:"150px", border:"none"}}/>):(<img src={file} width={200}/>)}
                    
                    </div>
                   
                    
                   

                   
                 
                  </div>
                </div>
</div>
</div>
             


          </div>
        </div>

        <div className="row">
        <div className="col-6-xxxl col-12">
          <div className="card">
            <div className="card-body2" style={{ marginBottom: '15px'}}>            
             
              <div style={{padding:'10px', color:'#000000'}}>Categories:
                    
                   </div>    
                                                                             
                    <div className="row">
        <div className="col-12-xxxl col-12">
        <div className="mainbox">
     

       

        {allCategoriesData && allCategoriesData.all_categories_loading === true ?
          (<div className={editCatMode && editCatMode ===  true ? 'card-body hidden' : 'card-body'}>
          <div className="Student-Loader">
          <Loader
          className = "student-detail-loader"
        type="ThreeDots"
        color="#fea801"
        height={150}
        width={150}

/>
          </div>
        </div>) : (
        <div className={ editCatMode && editCatMode === true ? 'table-responsive expenses-table-box hidden' : 'table-responsive expenses-table-box'}>
  <div className="col-lg-12 col-12 form-group" style= {{height: "20vh", overflowY: "scroll"}}>
                <span className="">

  
          {allCategoriesResult && allCategoriesResult.length > 0 && allCategoriesResult.filter(list => list.status === categoryStatus).map((item,index)=>{
           
            return (
              <>
               <div className="col-12-xxxl col-12" style={{fontSize:"12px", width:"100%"}}>
              <label htmlFor={item.categoryId} key={item.categoryId} className="lableforaddon">
  
             
              <input
type='checkbox'
id={item.categoryId}
name={item.categoryName}
checked={checkedItems[item.categoryId]}
value={item.categoryId} // store the id here

onChange={(e) => {
// Set the input values state
if(!categoryArr.some(obj => obj === e.target.id)){ categoryArr.push(e.target.id) }
//console.log('===>>>'+JSON.stringify(categoryArr));
setInputValues({...inputValues, categories: categoryArr}); // access the id here
// Get the span element by its id
var span = document.getElementById("span" + e.target.id);
// Check if the checkbox is checked or not
if (e.target.checked) {
span.style.backgroundColor = "skyblue";
if(!categoryArr.some(obj => obj === e.target.id)){ categoryArr.push(e.target.id) }
} else {
span.style.backgroundColor = "white";
let index = categoryArr.indexOf(e.target.id);
categoryArr.splice(index, 1);
}
}}
style={{display:"none"}}
/>


<span id={"span" + item.categoryId} className="btn-fill-1md radius-30 text-dark bg-dodger-blue unselect">{item.categoryName} <span className="delete-addon"><i className="fa fa-times" aria-hidden="true"></i></span></span>
            </label>
            </div>
              
              </>
            )
        
          })}
</span>
</div>

       
      </div>)}
        </div>
        </div>
        </div>    
              
            </div>
          
          </div>
        </div>
     
        <div className="col-6-xxxl col-12">
          <div className="card">
            <div className="card-body2" style={{ marginBottom: '15px' }}>            
             
                <div style={{padding:'10px', color:'#000000'}}>Addons:
                     
                   </div>                                                                  
                   <div className="row">
                   <div className="col-12-xxxl col-12">
        <div className="mainbox">
      
            {editProductResp && editProductResp.type && editProductResp.type == 'success' ? <div style={{ textAlign: 'center', color: 'green' }}>{editProductResp.message}</div>
              : <div style={{ textAlign: 'center', color: 'red' }}>{editProductResp.message}</div>
            }
          

              <div className="col-lg-12 col-12 form-group" style= {{height: "20vh", overflowY: "scroll"}}>
                <span className="">


                  {allGroupResult && allGroupResult.length > 0 && allGroupResult.map((addonGroup, index) => {

                    return (
                      <label htmlFor={addonGroup.addOnGroupId} key={addonGroup.addOnGroupId} className="lableforaddon">
  
  <input
  type='checkbox'
                id={addonGroup.addOnGroupId}
                name={addonGroup.addOnGroupId}
                checked={checkedItems[addonGroup.addOnGroupId]}
                value={addonGroup.addOnGroupId} // store the id here
onChange={(e) => {
  // Set the input values state
  //console.log('=====>>>'+e.target.id);
  if(!addonGroupArr.some(obj => obj.addOnGroupId === e.target.id)){ addonGroupArr.push({addOnGroupId: e.target.id}) }
  //console.log('===>>>'+JSON.stringify(addonGroupArr));
  setInputValues({...inputValues, addons: addonGroupArr}); // access the id here
  // Get the span element by its id
  var span = document.getElementById("span" + e.target.id);
  // Check if the checkbox is checked or not
  if (e.target.checked) {
  span.style.backgroundColor = "skyblue";
  if(!addonGroupArr.some(obj => obj.addOnGroupId === e.target.id)){ addonGroupArr.push({addOnGroupId: e.target.id}) }
  } else {
    span.style.backgroundColor = "white";
    let index = addonGroupArr.findIndex(obj => obj.addOnGroupId === e.target.id);
    addonGroupArr.splice(index, 1);
  }
  }}

       style={{display:"none"}}         
              />
                      
                        <span id={"span" + addonGroup.addOnGroupId} className="btn-fill-1md radius-30 text-dark bg-dodger-blue unselect">{addonGroup.name} <span className="delete-addon"><i className="fa fa-times" aria-hidden="true"></i></span></span>

                      </label>
                    );
                  })
                  }
                </span>
              </div>

          


</div>
</div>
          </div>
      
      </div> 
              
            
          
          </div>
        </div>

      
      </div>

      {/* button disable or active */}
     
{btn?(<div className="col-12 form-group mg-t-8">
                      <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow" style={{float:'right', paddingTop:'5px', paddingBottom:'5px', paddingLeft:'15px', paddingRight:'15px'}}>Save</button>
                    </div>):(<div className="col-12 form-group mg-t-8">
                      <button type="submit" className="btn-fill-lg bg-light-dark" style={{float:'right', paddingTop:'5px', paddingBottom:'5px', paddingLeft:'15px', paddingRight:'15px'}} disabled>Save</button>
                    </div>)}
        



      </form>  

    </>

  );




}

export default AddProduct;
